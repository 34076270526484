import * as React from "react"
import Layout from "../components/layout"
import HubspotForm from 'react-hubspot-form'

const ContactUs = () => {
    return (

        <Layout>


            <div className="breadcrumb-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="row breadcrumb_box  align-items-center">
                                <div className="col-lg-6 col-md-6 col-sm-6 text-center text-sm-left">
                                    <h2 className="breadcrumb-title">Contact Us</h2>
                                </div>
                                <div className="col-lg-6  col-md-6 col-sm-6">
                                    <ul className="breadcrumb-list text-center text-sm-right">
                                        <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                                        <li className="breadcrumb-item active">Contact Us</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>






            <div className="site-wrapper-reveal border-bottom">



                <div className="contact-us-page-warpper mt-30 section-space--pb_120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div>
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <HubspotForm
                                                portalId='7653384'
                                                formId='0bdaac17-4c5b-4fe7-87a2-e0f1c36a688d'
                                                loading={<div>Loading...</div>}
                                            />
                                        </div>
                                        <div className="col-lg-4 ml-auto">
                                            <div className="conatact-info-text section-space--mt_60">
                                                <div className="product_socials mt-30">
                                                    <span className="label">FOLLOW & CONTACT US:</span>
                                                    <ul className="helendo-social-share socials-inline">
                                                        <li>
                                                            <a href="https://www.facebook.com/vibrahub">
                                                                <i className="social social_facebook"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://www.instagram.com/vibrahub/">
                                                                <i className="social social_instagram"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://www.pinterest.com/vibrahub/">
                                                                <i className="social social_pinterest"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contact-us-info-area section-space--mb_60 border-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="single-contact-info-item">
                                    <div className="icon">
                                        <i className="icon-clock3"></i>
                                    </div>
                                    <div className="iconbox-desc">
                                        <h6 className="mb-10">Open hours</h6>
                                        <p>Mon – Fri : 8:30 – 18:00 CET</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="single-contact-info-item">
                                    <div className="icon">
                                        <i className="icon-telephone"></i>
                                    </div>
                                    <div className="iconbox-desc">
                                        <h6 className="mb-10">Phone number</h6>
                                        <p>(+44) 7544 073 294  <br /> (+386) 70 293 029</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="single-contact-info-item">
                                    <div className="icon">
                                        <i className="icon-envelope-open"></i>
                                    </div>
                                    <div className="iconbox-desc">
                                        <h6 className="mb-10">Our email</h6>
                                        <p>info@vibrahub.com </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </Layout>

    )
}

export default ContactUs
